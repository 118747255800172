import React from "react"
import ConfirmButton from "../ConfirmButton"
import { CloseOutlined, CheckOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"

const EnableButton = ({ isTranslate = false, isEnabled, ...rest }) => {
  const { formatMessage } = useIntl()
  const confirmDisable = isTranslate
    ? formatMessage({ id: "confirmDisable" })
    : "Confirm to disable ?"
  const confirmEnable = isTranslate
    ? formatMessage({ id: "confirmEnable" })
    : "Confirm to enable?"

  const disableLabel = isTranslate
    ? formatMessage({ id: "disable" })
    : "Disable"
  const enableLabel = isTranslate ? formatMessage({ id: "enable" }) : "Enable"

  const cancelLabel = isTranslate ? formatMessage({ id: "cancel" }) : "Cancel"

  return (
    <ConfirmButton
      size="small"
      shape="round"
      title={isEnabled ? confirmDisable : confirmEnable}
      icon={isEnabled ? <CloseOutlined /> : <CheckOutlined />}
      popProps={{
        okText: isEnabled ? disableLabel : enableLabel,
        cancelText: cancelLabel,
      }}
      {...rest}
    >
      {isEnabled ? disableLabel : enableLabel}
    </ConfirmButton>
  )
}

export default EnableButton
