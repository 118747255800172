import { IDENTITY_CONFIG, METADATA_OIDC } from "./authConst"
import { UserManager } from "oidc-client"
import { navigate } from "gatsby"

export const isBrowser = () => {
  return typeof window !== "undefined"
}
export const mgr = isBrowser()
  ? new UserManager({
      ...IDENTITY_CONFIG,
      metadata: METADATA_OIDC,
    })
  : null

export const getUser = () => {
  const user = isBrowser()
    ? sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.CLIENT_ID}`
      )
    : null
  return user
}

export const getPermission = async () => {
  const user = await mgr?.getUser()
  let profile = user.profile
  if ("Permission" in profile) {
    return profile.Permission
  }
  return null
}

export const login = () => {
  mgr?.signinRedirect({ state: window.location.href })
}

export const logoutCallback = () => {
  mgr
    .signoutRedirectCallback()
    .then(
      isBrowser() &&
        window.location.replace(
          process.env.REACT_APP_URL + "/Identity/Account/Login"
        )
    )
}

export const removeUser = () => {
  mgr?.removeUser()
}

export const logout = () => {
  console.log("logout")
  mgr?.signoutRedirect()
  localStorage.removeItem("currentUser")
}

export const checkIsCurrentUser = user => {
  const userId = user.profile.sub
  const currentUser = localStorage.getItem("currentUser")
  const isSame = userId === currentUser

  if (!isSame) window.alert("當前帳戶已經登出\nCurrent account is signed out.")

  return isSame
}

export const isLoggedIn = path => {
  if (["/authentication/*"].includes(path)) return true

  const storage = getUser()
  const user = storage ? JSON.parse(storage) : null
  let isSame = false
  if (user) isSame = checkIsCurrentUser(user)
  return isSame && !!user && !!user?.access_token
}

export const loginCallback = async () => {
  try {
    const result = await mgr?.signinRedirectCallback()
    const user = getUser()
    const staffNo = user ? JSON.parse(user).profile.sub : ""
    localStorage.setItem("currentUser", staffNo)
    if (result) window.location = result.state
  } catch (err) {
    console.log(err)
    if (err.message.includes("No matching state found in storage")) {
      navigate("/", { replace: true })
    } else {
      isBrowser() &&
        window.location.replace(
          process.env.REACT_APP_URL + "/Identity/Account/Login"
        )
    }
  }
}

export const addStorageListen = () => {
  if (isBrowser()) window.addEventListener("storage", checkIsCurrentUser, false)
}

export const loginSilentCallback = () => {
  console.log("silent Callback")
  try {
    mgr?.signinSilentCallback()
  } catch (err) {
    logout()
  }
}

export const checkPermission = async str => {
  const permission = await getPermission()
  if (permission !== null) {
    if (Array.isArray(permission)) {
      return permission.includes(str)
    } else {
      return permission === str
    }
  }

  return false
}

export const getStaffNo = () => {
  if (typeof window !== "undefined") {
    let user = sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.CLIENT_ID}`
    )
    user = JSON.parse(user)
    return user ? user.profile.sub : null
  }
  return null
}

export const clearStale = () => {
  console.log("Clear stale")
  mgr?.clearStaleState()
}
