import React from "react"
import { Empty as CustomEmpty } from "antd"
import { FormattedMessage } from "react-intl"

const Empty = ({ ...rest }) => {
  return (
    <CustomEmpty description={<FormattedMessage id="noRecord" />} {...rest} />
  )
}

export default Empty
