import React from "react"
import { Modal } from "antd"
import { useIntl } from "react-intl"

const FormModal = ({
  isTranslate = false,
  hiddenPrefix = false,
  isCreate,
  form,
  onCancel,
  title,
  children,
  isLoading,
  visible,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const addLabel = isTranslate ? formatMessage({ id: "add" }) : "Add "
  const editLabel = isTranslate ? formatMessage({ id: "edit" }) : "Edit "

  const changeLabel = isTranslate ? formatMessage({ id: "change" }) : "Change"
  const cancelLabel = isTranslate ? formatMessage({ id: "cancel" }) : "Cancel"
  return (
    <Modal
      centered
      forceRender
      title={
        isCreate
          ? `${!hiddenPrefix ? addLabel : ""}${title}`
          : `${!hiddenPrefix ? editLabel : ""}${title}`
      }
      destroyOnClose={true}
      visible={visible}
      okText={isCreate ? addLabel : changeLabel}
      cancelText={cancelLabel}
      onCancel={
        !isLoading
          ? onCancel
          : () => {
              console.log("isLoading")
            }
      }
      onOk={() => form.submit()}
      afterClose={() => form.resetFields()}
      confirmLoading={isLoading}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default FormModal
