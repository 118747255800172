import React from "react"
import { isLoggedIn, login } from "./auth"
import { Loading } from "lib"

const PrivateRoute = ({ children, path }) => {
  const isAuth = isLoggedIn(path)

  if (!isAuth) {
    login()
    return <Loading />
  }

  return children
}

export default PrivateRoute
