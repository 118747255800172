exports.components = {
  "component---src-pages-admin-panel-js": () => import("./../../../src/pages/admin_panel.js" /* webpackChunkName: "component---src-pages-admin-panel-js" */),
  "component---src-pages-appraisal-[id]-js": () => import("./../../../src/pages/appraisal/[id].js" /* webpackChunkName: "component---src-pages-appraisal-[id]-js" */),
  "component---src-pages-authentication-js": () => import("./../../../src/pages/authentication.js" /* webpackChunkName: "component---src-pages-authentication-js" */),
  "component---src-pages-configuration-approval-path-js": () => import("./../../../src/pages/configuration/approvalPath.js" /* webpackChunkName: "component---src-pages-configuration-approval-path-js" */),
  "component---src-pages-configuration-attendance-js": () => import("./../../../src/pages/configuration/attendance.js" /* webpackChunkName: "component---src-pages-configuration-attendance-js" */),
  "component---src-pages-configuration-core-competency-set-js": () => import("./../../../src/pages/configuration/coreCompetencySet.js" /* webpackChunkName: "component---src-pages-configuration-core-competency-set-js" */),
  "component---src-pages-configuration-cycle-js": () => import("./../../../src/pages/configuration/cycle.js" /* webpackChunkName: "component---src-pages-configuration-cycle-js" */),
  "component---src-pages-configuration-degree-360-js": () => import("./../../../src/pages/configuration/degree360.js" /* webpackChunkName: "component---src-pages-configuration-degree-360-js" */),
  "component---src-pages-configuration-goal-setting-set-js": () => import("./../../../src/pages/configuration/goalSettingSet.js" /* webpackChunkName: "component---src-pages-configuration-goal-setting-set-js" */),
  "component---src-pages-configuration-kpi-js": () => import("./../../../src/pages/configuration/KPI.js" /* webpackChunkName: "component---src-pages-configuration-kpi-js" */),
  "component---src-pages-configuration-target-js": () => import("./../../../src/pages/configuration/target.js" /* webpackChunkName: "component---src-pages-configuration-target-js" */),
  "component---src-pages-configuration-template-js": () => import("./../../../src/pages/configuration/template.js" /* webpackChunkName: "component---src-pages-configuration-template-js" */),
  "component---src-pages-configuration-view-template-js": () => import("./../../../src/pages/configuration/viewTemplate.js" /* webpackChunkName: "component---src-pages-configuration-view-template-js" */),
  "component---src-pages-frontline-js": () => import("./../../../src/pages/frontline.js" /* webpackChunkName: "component---src-pages-frontline-js" */),
  "component---src-pages-frontline-search-[id]-js": () => import("./../../../src/pages/frontline_search/[id].js" /* webpackChunkName: "component---src-pages-frontline-search-[id]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-submission-js": () => import("./../../../src/pages/my_submission.js" /* webpackChunkName: "component---src-pages-my-submission-js" */),
  "component---src-pages-pdf-report-[id]-js": () => import("./../../../src/pages/PdfReport/[id].js" /* webpackChunkName: "component---src-pages-pdf-report-[id]-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-role-search-js": () => import("./../../../src/pages/role_search.js" /* webpackChunkName: "component---src-pages-role-search-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

