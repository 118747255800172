import React from "react"
import ConfirmButton from "../ConfirmButton"
import { DeleteOutlined } from "@ant-design/icons"
import { useIntl } from "react-intl"

const DeleteButton = ({ isTranslate = false,children , isEnabled, ...rest }) => {
  const { formatMessage } = useIntl()

  const deleteLabel = isTranslate ? formatMessage({ id: "delete" }) : "Delete"
  const cancelLabel = isTranslate ? formatMessage({ id: "cancel" }) : "Cancel"
  const confirmDelete = isTranslate
    ? formatMessage({ id: "confirmDelete" })
    : "Confirm to Delete ?"
  return (
    <ConfirmButton
      size="small"
      title={confirmDelete}
      icon={<DeleteOutlined />}
      shape="round"
      popProps={{ okText: deleteLabel, cancelText: cancelLabel }}
      {...rest}
    >
      {children??deleteLabel}
    </ConfirmButton>
  )
}

export default DeleteButton
