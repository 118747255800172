import React, { useState, useEffect } from "react"
import { Popover, Progress } from "antd"
import ScoreTooltip from "../ScoreTooltip"
import { AppraisalClient, AppraisalTemplateClient } from "@api"

const ScoreProgressCircle = ({
  finalScore,
  scoreJSON,
  appraisalTemplateId,
  appraisalId,
  ...rest
}) => {
  let strokeColor = "white"
  if (finalScore < 60) {
    strokeColor = "red"
  } else if (finalScore >= 60) {
    strokeColor = "green"
  }
  const [isShow, setIsShow] = useState(false)
  const [template, setTemplate] = useState(false)
  const [isloading, setIsLoading] = useState(false)

  const fetchTemplate = async id => {
    try {
      setIsLoading(true)
      const client = new AppraisalClient()
      const appraisalContent = await client.get2(id)
      const templateClient = new AppraisalTemplateClient()
      const templateResult = await templateClient.get2(
        appraisalContent.content.appraisal.appraisalTemplateId
      )
      setTemplate(templateResult)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  //Enter the submission
  useEffect(() => {
    if (appraisalId) fetchTemplate(appraisalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisalId])

  return (
    <Popover
      placement="left"
      color="white"
      overlayStyle={{ width: "20%" }}
      onVisibleChange={open => setIsShow(open)}
      content={() => (
        <ScoreTooltip
          isShoow={isShow}
          key={appraisalId}
          detail={scoreJSON}
          appraisalTemplateId={template.id}
        />
      )}
      {...rest}
    >
      <Progress
        type="circle"
        strokeColor={strokeColor}
        percent={finalScore}
        width="40px"
        format={percent => percent}
      />
    </Popover>
  )
}

export default ScoreProgressCircle
