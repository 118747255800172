const zh = {
  siteTitle: "啟勝網上績效評估系統",
  //Header
  changePW: "更改密碼",
  logout: "登出",
  hello: "哈囉, ",

  //Page Title
  myTaskTitle: "待辦事項",
  mySubmissionTitle: "已提交事項",
  apprisaeeInfo: "受評人資料",
  appraisalHistory: "評估紀錄",
  coreCompetencies: "核心能力標準",
  additionalScore: "額外分數",
  goalSetting: "目標訂立",
  overview: "總覽",
  siteKPI: "物業績效",
  degree360: "360° 評估",
  comments: "評語",
  adminPanel: "管理",
  avgRating: "平均分數",
  reportTitle: "報告",
  pastAppraisal: "過往績效",
  goalModalTitle: "目標達成時間: {startYear} to {endYear}",

  //Common column / label
  appraisee: "受評人",
  supervisor: "直屬上司",

  email: "電子郵件",
  appraiseeName: "受評人姓名",
  appraisalNo: "評估編號",
  staffNo: "員工編號",
  staffName: "員工姓名",
  appraisalType: "評估類型",
  site: "物業 / 部門",
  position: "職位",
  appraisalCycleName: "評估名稱",
  status: "狀態",
  appraisalPeriod: "評估時期",
  finalScore: "最終分數",
  finalScore2: "總分{name}",
  fullName: "姓名",
  company: "公司",
  dateJoined: "入職日期",
  yearInCurrentPosition: "現職位年資",
  reviewPeriod: "評估年度",
  date: "日期",
  score: "分數",
  subtotal: "小計",
  currentScore: "現時分數{name}",
  rating: "評分",
  item: "項目",
  title: "標題",
  description: "描述",
  attachment: "檔案",
  goals: "目標",
  plan: "計劃",
  trainingPlan: "培訓與發展計劃",
  expectedResults: "預期成績",
  remarkCommentTitle: "{staff} 的評語",
  returningFormAmendment: "{staff} - 退回評估的原因",
  lateness: "遲到 ({name})",
  times: "次數",
  mins: "分鐘",
  failToClock: "欠打卡(次數)",
  managerial: "經理級",
  nonManagerial: "非經理級",
  frontline: "前線",
  totalScore: "總分數",
  lastSavedOn: "最後儲存時間: {date} {time}",
  active: "在職",
  inactive: "待職",
  terminated: "離職",
  staffStatus: "員工狀態",
  role: "角色",
  supportingDocuments: "檔案",
  uploadSupportingDocuments: "上傳附加證明文件",
  uploadIndiFormHelper: "系統會掃瞄上傳的PDF，及為指定的評估填上分數",

  //Button
  returnForXs: "退回",
  submit: "提交",
  approve: "審批",
  view: "查閱",
  recall: "回收",
  return: "退回",
  action: "動作",
  search: "搜尋",
  adminSearch: "搜尋 (管理員)",
  clear: "重設",
  cancel: "取消",
  edit: "編輯",
  back: "返回",
  previous: "上一頁",
  next: "下一頁",
  delete: "刪除",
  add: "加入",
  invalid: "無效",
  save: "保存",
  close: "關閉",
  upload: "{name}上傳",
  Submit: "提交",
  Review: "檢閱",
  Approve: "審批",
  Submitted: "己提交",
  Acknowledge: "確認",
  returnv2: "退回",
  ok: "確認",
  change: "更改",
  print: "列印",
  download: "下載",

  //other
  inProgress: "進行中",
  noAdjust: "沒有修改",
  you: "你",
  subordinate: "下屬",
  noComments: "沒有評語",
  goalPeriod: "目標達成時間: ",
  other: "其他",
  comment: "評語",
  noRecord: "沒有記錄",
  remarks: "備註",
  returnModal: "此評估將被退回到上一級別",

  //placeHolder
  sureTo: "確認{name}?",
  returnReason: "請寫下您退回此評估表的原因",
  returnAlert:
    "您在此評估中所編寫的分數和意見將被獨立儲存，受評人進行修改時將不會看見。當受評人重新提交評估予您審批時，您可按目前進度繼續編輯。",
  returnWarning: "注意: 退回此表格後，你的填寫記錄將不會保留。",
  textAreaReviewComment: "請填寫你的意見",
  fillInFeedback: "請填寫你的意見和附上相關證明文件",
  maxFileSize: "上傳文件限制: {name}",
  fileType: "文件格式: {name}",
  writeAppriopriate: "請適當填寫",
  resultExample: "預期結果 (如質素、質量、時間性等)",
  selectPlaceholder: "請選擇{name}",
  inputPlaceholder: "請輸入{name}",
  pleaseInput: "請輸入{name}",
  pleaseSelect: "請選擇{name}",
  tbc: "有待確定",
  siteKpiDisclaimer :"Site KPI有待內部稽核部作最終調整，分數確認後將於系統自動更新",
  error: "發生系統錯誤",

  //Change-Template
  changeTemplate: "更改範本",
  changeKPI: "更改物業績效",
  template: "範本",

  //SiteKPI History
  siteKPIHistory: "更改物業績效紀錄",
  isCurrent: "(目前物業績效: {name})",
  personalKPI: "個人物業績效",

  cannotRecall: "此評估已被檢閱, 不能回收",
  yearOfAppraisal: "評估年度",

  pending: "進行中",
  submitted: "已提交",

  order: "排序",
  ascend: "升序",
  descend: "降序",

  cannotBatchApprove: "此評估未完成",
  appraisalList: "績效評估列表",
  batchApprove: "批量審批",
  readyToSubmit: "可覆核",

  batchUpload: "批量上傳評估表格",
  batchExport: "匯出全部評估表格",
  export: "匯出個別評估表格",
  deletePdf : "移除附加證明文件",

  MicroSoftWordDescription: "請使用Word 2013或之後的版本讀取評估表格上的二維碼",
  hasFilterDescription: "請先使用 評估年度 與 物業/部門 搜尋記錄",

  scanningProgress: "掃描進度",

  startTime: "開始時間",
  endTime: "結束時間",
  uploadTime: "上傳時間",
  uploadBy: "上傳用戶",

  scanAlert:
    "按 上傳個別評估表格 ，為個別評估上傳PDF檔，系統會掃瞄上傳的PDF，及為指定的評估填上分數",
  supportDocReminder: "如分數低於60，請上載員工績效改善計劃",
  scan: "上傳個別評估表格",
  processing: "掃描中",
  completed: "完成",
  failed: "失敗",

  uploadSuccess: "上傳成功",

  yes: "是",
  no: "否",

  unrecognizedPages: "不能識別的頁數: {pages}",
  appendAttachment:
    "按 檔案上傳 可上傳附加證明文件，文件會被附加在己上傳檔案中",

  frontlineReturnAlert:
    "以下備註/評語將顯示在評估表格的「評估紀錄」部分，所有人均可查看。",
  frontlineReturnWarning: "注意: 退回此表格後，你的填寫記錄將不會保留。",

  uploadPdfHelper: "只接受PDF檔案格式",

  cancelTask: "取消掃描",

  cancelReason: "請寫下您取消此掃描任務的原因",
  cancelled: "已取消",

  missingScore: "請輸入分數",

  hasPDF: "已上傳PDF",

  confirmTo: "確認{action}評估？",

  lastSubmitWarning: "注意: 提交後不能再次更改此評估",

  fileLimit: "檔案不能大於{size}Mbs",

  appraisalStatus: "評估狀態",

  appraiseeInfo: "受評人資料",

  printAsPDF: "儲存成PDF",

  notFinishedForm: "提交前請先完成表格",

  currentSelect: " - 目前選擇",

  default: "預設",
  custom: "自訂",
  password: "密碼",
  defaultPassword: "預設密碼 = 員工編號 + 英文姓氏 (例子: 123456Chan)",

  disable: "停用",
  enable: "啟用",

  confirmDisable: "確認停用?",
  confirmEnable: "確認啟用?",
  confirmDelete: "確認刪除?",
  confirmRecall: "確認回收評估?",

  user: "用戶",

  firstName: "名字",
  lastName: "姓氏",

  passwordFormate:
    " 密碼必須包含八個字元，且包含至少一個大寫字母，至少一個小寫字母和至少一個數字",

  onlyUpperCaseLetter: "只接受大寫英文字母",

  disableOtherUsers: "停用其他用戶",
  file: "檔案",

  //admin-panel
  users: "用戶資料",
  userList: "用戶列表",
  immediateSupervisor: "直屬上司",
  approvalPath: "審批路徑",
  approvalRole: "審批角色",
  defaultApprovers: "預設審批路徑",

  generateRandomSubordinate: "隨機抽取下屬",
  supervisorDivider: "直屬上司",

  appraiseeStaffNo: "受評人員工編號",
  supervisorStaffNo: "直屬上司員工編號",
  supervisorStaffName: "直屬上司員工姓名",
  supervisorLevel: "審批順序",
  reason: "原因",

  Excel: "試算表",
  replaceAllData: "取代所有資料",

  subordinateNumber: "下屬數目",
  isPadding: "是否填充",

  disableUserReason: "停用原因",
  disableUserPlaceholder: "請填寫停用原因 (例如長病假, 產假等)",
  disableUserRemarkInactive: "待職 = 長病假及產假等",
  disableUserRemarkTerminated: "離職 = 已辭職 / 離職",
  pwPattern:
    "密碼必須包含八個字元，且包含至少一個大寫字母，至少一個小寫字母和至少一個數字",
  monday: "星期一",
  tuesday: "星期二",
  wednesday: "星期三",
  thursday: "星期四",
  friday: "星期五",
  saturday: "星期六",
  sunday: "星期日",
}

export default zh
