import React from "react"
import { Table } from "antd"

const PanelTable = ({
  dataSource,
  columns,
  pageMeta,
  isLoading,
  setSearchParams,
  defaultOrder,
  isTranslate = false,
  ...rest
}) => {
  const handleTableChange = (pagination, b, sorter, { action }) => {
    if (action === "paginate") {
      setSearchParams({
        pageIndex: pagination.current - 1,
        pageSize: pagination.pageSize,
      })
    } else if (action === "sort") {
      if (!!sorter.order) {
        setSearchParams({
          orderBy: sorter.field,
          isAscend: sorter.order === "ascend",
        })
      } else {
        setSearchParams(defaultOrder)
      }
    }
  }

  return (
    <Table
      rowKey="id"
      locale={
        isTranslate
          ? {}
          : {
              emptyText: "No Data",
              triggerDesc: "Click sort by descend",
              triggerAsc: "Click sort by ascend",
              cancelSort: "Click to cancel sort",
            }
      }
      loading={isLoading}
      size="small"
      onChange={handleTableChange}
      dataSource={dataSource}
      columns={columns}
      pagination={{
        current: pageMeta.currentPage,
        position: ["bottomCenter"],
        total: pageMeta.totalCount,
        pageSize: pageMeta.pageSize,
      }}
      {...rest}
    />
  )
}

export default PanelTable
