import React from "react"
import { Button } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"

const EditButton = ({ isTranslate = false, onClick, ...rest }) => (
  <Button
    icon={<EditOutlined />}
    size="small"
    shape="round"
    onClick={onClick}
    {...rest}
  >
    {isTranslate ? <FormattedMessage id="edit" /> : "Edit"}
  </Button>
)

export default EditButton
