import React from "react"
import { LangProvider } from "./src/context"
import { clearStale } from "@services/auth"
import PrivateRoute from "@services/PrivateRoute"
export const onClientEntry = () => {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode
  if (isIE) {
    alert(
      "此系統並不支援 Internet Explorer\n請使用Google Chrome 或 Microsoft Edge 瀏覽器以獲得最佳瀏覽體驗\n\nThis system does not support Internet Explorer\nPlease use Google Chrome or Microsoft Edge for the best experience"
    )
    window.open("", "_self", "")
    window.close()
  }
  if (
    window.localStorage.getItem(
      "oidc.user:" + process.env.REACT_APP_AUTH_URL + ":KaiShing.WebUI"
    ) !== null
  ) {
    window.localStorage.removeItem(
      "oidc.user:" + process.env.REACT_APP_AUTH_URL + ":KaiShing.WebUI"
    )
  }
  clearStale()
}

export const wrapRootElement = ({ element }) => {
  return <LangProvider>{element}</LangProvider>
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload(true)
}

export const wrapPageElement = ({ element, props }) => {
  return <PrivateRoute {...props}>{element}</PrivateRoute>
}
