import React from "react"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"

const YesOrNoIcon = ({ value }) =>
  value ? (
    <CheckCircleOutlined style={{ color: "green" }} />
  ) : (
    <CloseCircleOutlined style={{ color: "red" }} />
  )

export default YesOrNoIcon
