import React from "react"
import { FormattedMessage } from "react-intl"

const UploadStatusTag = ({ value }) => {
  switch (value) {
    case 0:
      return <FormattedMessage id="pending" />
    case 1:
      return <FormattedMessage id="processing" />

    case 2:
      return <FormattedMessage id="completed" />

    case 3:
      return <FormattedMessage id="failed" />

    case 4:
      return <FormattedMessage id="cancelled" />
    default:
      return null
  }
}

export default UploadStatusTag
