import React from "react"
import { Button, Popconfirm } from "antd"

const ConfirmButton = ({
  title,
  okText,
  onClick,
  children,
  popProps,
  ...rest
}) => {
  return (
    <Popconfirm
      title={title}
      okText={okText}
      cancelText="Cancel"
      onConfirm={onClick}
      {...popProps}
    >
      <Button {...rest}>{children}</Button>
    </Popconfirm>
  )
}
export default ConfirmButton
