import React, { useState, useEffect, useContext } from "react"
import { Divider, Descriptions, Tag, Row, Col, Spin } from "antd"
import { FormattedMessage } from "react-intl"
import { ContextStore } from "@context"
import { SyncOutlined } from "@ant-design/icons"
import { AppraisalTemplateClient } from "@api"

import "@style/scoreProgressCircle.less"

const ScoreTooltip = ({ detail, appraisalTemplateId, isShow }) => {
  const item = JSON.parse(detail)
  const { locale } = useContext(ContextStore)
  const [isLoading, setIsLoading] = useState(false)
  const [template, setTemplate] = useState(null)

  const translate = (arr, str) => {
    if (Array.isArray(arr) && arr.length > 0 && !!str) {
      const matchStr = str.includes("zh") ? "zh-HK" : "en-US"
      const item = arr.find(item => item.CultureInfoName === matchStr)
      return item?.Message
    }

    return " - "
  }

  const InitPage = async id => {
    try {
      setIsLoading(true)
      const templateClient = new AppraisalTemplateClient()

      let templateRes = await templateClient.get2(id)
      setTemplate(templateRes)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  //Enter the submission
  useEffect(() => {
    if (appraisalTemplateId) InitPage(appraisalTemplateId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  return (
    <div className="mytask-score-tooltip">
      <Spin spinning={isLoading}>
        <Divider orientation="left">
          <FormattedMessage id="coreCompetencies" />
          {" (" + template?.coreCompetencyWeighting + "%)"}
        </Divider>
        <Descriptions column={1}>
          <Descriptions.Item label={<FormattedMessage id="score" />}>
            {item
              ? item.CoreCompetencyScore + " / " + item.CoreCompetencyMaxScore
              : null}
          </Descriptions.Item>
          {item &&
            item.ScoreAdjustmentGroup.map(item => (
              <Descriptions.Item label={translate(item.Name, locale)}>
                {item.TotalScore + ""}
              </Descriptions.Item>
            ))}
          <Descriptions.Item
            className="sub-score"
            label={<FormattedMessage id="subtotal" />}
          >
            {item?.CoreCompetencySubtotal + ""}
          </Descriptions.Item>
        </Descriptions>
        {item && (item.SiteKPIMaxScore > 0 || item.IsSiteKPIPending) ? (
          <>
            <Divider orientation="left">
              <FormattedMessage id="siteKPI" />
              {" (" + template?.siteKPIWeighting + "%)"}
            </Divider>
            <Descriptions column={1}>
              <Descriptions.Item label={<FormattedMessage id="score" />}>
                {!item.IsSiteKPIPending ? (
                  item.SiteKPIScore + " / " + item.SiteKPIMaxScore
                ) : (
                  <FormattedMessage id="tbc" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                className="sub-score"
                label={<FormattedMessage id="subtotal" />}
              >
                {item.SiteKPIMaxScore > 0 ? item.SiteKPISubtotal : null}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : null}
        {item && item.Degree360MaxScore > 0 ? (
          <>
            <Row justify="space-between" align="middle">
              <Col>
                <Divider orientation="left">
                  <FormattedMessage id="degree360" />
                  {" (" + template?.degree360Weighting + "%)"}
                </Divider>
              </Col>
              <Col>
                {!item.IsDegree360Completed && (
                  <Tag icon={<SyncOutlined />} color="processing">
                    <FormattedMessage id="inProgress" />
                  </Tag>
                )}
              </Col>
            </Row>
            <Descriptions column={1}>
              <Descriptions.Item label={<FormattedMessage id="score" />}>
                {item.Degree360Score + " / " + item.Degree360MaxScore}
              </Descriptions.Item>
              <Descriptions.Item
                className="sub-score"
                label={<FormattedMessage id="subtotal" />}
              >
                {item.Degree360Subtotal + ""}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : null}
        <Divider orientation="right">
          <FormattedMessage id="finalScore" />
          :&nbsp;
          {item?.FinalScore + ""}
        </Divider>
      </Spin>
    </div>
  )
}

export default ScoreTooltip
