import React, { useState, createContext } from "react"
import zh from "../i18n/zh"
import en from "../i18n/en"
import { IntlProvider } from "react-intl"
import enUS from "antd/lib/locale/en_US"
import zhTW from "antd/lib/locale/zh_TW"
import { ConfigProvider } from "antd"
import { mgr, logout, isBrowser } from "@services/auth"

export const ContextStore = createContext()

export const LangProvider = ({ children }) => {
  mgr?.events.addAccessTokenExpired(() => {
    try {
      mgr?.signinSilent().then(user => {
        if (!user && user.expired) logout()
      })
    } catch (err) {
      logout()
    }
  })
  mgr?.events.addSilentRenewError(() => {
    console.log("slient Renew failed")
    logout()
  })
  mgr?.events.addUserSignedOut(() => {
    console.log("Remove User")
    mgr?.removeUser()
  })

  const initialLang = isBrowser() ? localStorage.getItem("lang") : null

  const defaultLang = isBrowser() ? navigator.language : "en"

  const [locale, setLocale] = useState(initialLang ?? defaultLang)

  const IntlMsg = {
    zh: zhTW,
    en: enUS,
  }

  const changeLanguage = lang => {
    localStorage.setItem("lang", lang)
    setLocale(lang)
  }

  return (
    <ContextStore.Provider
      value={{ locale: locale, changeLanguage: changeLanguage }}
    >
      <IntlProvider
        locale={locale}
        key={locale}
        messages={locale.includes("zh") ? zh : en}
      >
        <ConfigProvider locale={IntlMsg[locale]}>{children}</ConfigProvider>
      </IntlProvider>
    </ContextStore.Provider>
  )
}
