import React from "react"

const FilledDivider = ({ children }) => {
  return (
    <div
      style={{
        fontSize: "16px",
        color: "#958655",
        background: "#f1eee6",
      }}
    >
      {children}
    </div>
  )
}

export default FilledDivider
