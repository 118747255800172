const TemplateTypeTag = ({ value }) => {
  switch (value) {
    case 1:
      return "Managerial"
    case 0:
      return "Non-Managerial"
    case 2:
      return "Frontline"
    case 3:
      return "Non-Degree360"
    default:
      return null
  }
}

export default TemplateTypeTag
