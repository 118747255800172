const en = {
  siteTitle: "Kai Shing e-Appraisal System",
  //Header
  changePW: "Change Password",
  logout: "Logout",
  hello: "Hello, ",

  //Page Title
  myTaskTitle: "My Task",
  mySubmissionTitle: "My Submission",
  apprisaeeInfo: "Appraisee Information",
  appraisalHistory: "Appraisal History",
  coreCompetencies: "Core Competencies",
  additionalScore: "Additional Score",
  goalSetting: "Goal Setting",
  overview: "Overview",
  siteKPI: "Site KPI",
  degree360: "360° Evaluation",
  comments: "Comments",
  adminPanel: "Admin Panel",
  avgRating: "Average Score",
  reportTitle: "Reports",
  pastAppraisal: "Past Appraisal",
  goalModalTitle: "Goal Achievement Period: {startYear} to {endYear}",

  //Common column / label
  appraisee: "Appraisee",
  supervisor: "Immediate Supervisor",

  email: "Email",
  appraiseeName: "Appraisee Name",
  appraisalNo: "Appraisal Number",
  staffNo: "Staff Number",
  staffName: "Staff Name",
  appraisalType: "Appraisal Type",
  site: "Site / Department",
  position: "Position",
  appraisalCycleName: "Appraisal Cycle Name",
  status: "Status",
  appraisalPeriod: "Appraisal Period",
  finalScore: "Final Score",
  finalScore2: "Final Score{name}",
  fullName: "Full Name",
  company: "Company",
  dateJoined: "Date Joined",
  yearInCurrentPosition: "Years In Current Position",
  reviewPeriod: "Review Period",
  date: "Date",
  score: "Score",
  subtotal: "Subtotal",
  currentScore: "Current Score{name}",
  rating: "Rating",
  item: "Item",
  title: "Title",
  description: "Description",
  attachment: "Attachment",
  goals: "Goals",
  plan: "Plan",
  trainingPlan: "Training and Development Plan",
  expectedResults: "Expected Results",
  remarkCommentTitle: "Comments from {staff}",
  returningFormAmendment: "{staff} - Reason of returning form for amendment",
  lateness: "Lateness ({name})",
  times: "Times",
  mins: "Minutes",
  failToClock: "Failing to clock in / out",
  managerial: "Managerial",
  nonManagerial: "Non-Managerial",
  frontline: "Frontline",
  totalScore: "Total Score",
  lastSavedOn: "Last saved on {date} at {time}",
  active: "Active",
  inactive: "Inactive",
  terminated: "Terminated",
  staffStatus: "Staff Status",
  approvalPath: "Approval Path",
  role: "Role",
  supportingDocuments: "Supporting Documents",
  uploadSupportingDocuments: "Upload Supporting Documents",
  uploadIndiFormHelper:
    "System would scan PDF file and auto fill in the scores for special Appraisal.",

  //Button
  returnForXs: "Return",
  submit: "Submit",
  approve: "Approve",
  view: "View",
  recall: "Recall",
  return: "Return for Amendment",
  action: "Action",
  search: "Search",
  adminSearch: "Search (Admin)",
  clear: "Clear",
  cancel: "Cancel",
  edit: "Edit",
  back: "Back",
  previous: "Previous",
  next: "Next",
  delete: "Delete",
  add: "Add",
  invalid: "Invalid",
  save: "Save",
  close: "Close",
  upload: "Upload {name}",
  Submit: "Submit",
  Review: "Review",
  Approve: "Approve",
  Submitted: "Submitted",
  Acknowledge: "Acknowledge",
  returnv2: "Return",
  ok: "OK",
  print: "Print",

  change: "Change",
  download: "Download",

  //other
  inProgress: "In progress",
  noAdjust: "No Adjustment",
  you: "You",
  subordinate: "Subordinate",
  noComments: "Nobody leaves the comment.",
  goalPeriod: "Goal Achievement Period: ",
  other: "Other",
  comment: "Comment",
  noRecord: "No Record",
  remarks: "Remarks",
  returnModal:
    "This appraisal form will be returned to your previous level for amendment",

  //placeHolder
  sureTo: "Sure to {name}?",
  returnReason:
    "Please write down your reason(s) of returning this appraisal form",
  returnAlert:
    "The comments and adjustments you have made in this form will be saved separately and invisible to appraisee when the form is returned for amendment. You may continue editing with current progress when the form is resubmitted for your approval.",
  returnWarning:
    "Note: Your comments and adjustments in this form will not be kept after returning the form.",
  textAreaReviewComment: "Please fill in your comment",
  fillInFeedback: "Please fill in your feedback and supporting evidence.",
  maxFileSize: "Maximum upload file size: {name}.",
  fileType: "Allowed types: {name}",
  writeAppriopriate: "Please write as appropriate",
  resultExample: "(E.g. Quality, quantity, Timeframe, etc.)",
  selectPlaceholder: "Select a {name}",
  inputPlaceholder: "Input {name}",
  pleaseInput: "Please input {name}",
  pleaseSelect: "Please select {name}",
  tbc: "TBC",
  siteKpiDisclaimer :"The score is subjected to final adjustment of Site KPI from Internal Audit Department",
  error: "System Error Occurred",

  //order
  triggerDesc: "Click to sort descending",
  triggerAsc: "Click to sort ascending",
  cancelSort: "Click to cancel sort",

  //Change-Template
  changeTemplate: "Change Template",
  changeKPI: "Change KPI",
  template: "Template",

  //SiteKPI History
  siteKPIHistory: "Site KPI History",
  isCurrent: "(Current KPI(s): {name})",
  personalKPI: "Personal KPI",

  cannotRecall: "Cannot recall as this appraisal form has been reviewed",

  yearOfAppraisal: "Year of Appraisal",
  pending: "Pending",
  submitted: "Submitted",

  order: "Order",
  ascend: "Ascend",
  descend: "Descend",

  cannotBatchApprove: "The Form is not completed yet.",
  appraisalList: "Appraisal List",
  batchApprove: "Batch Approve",
  readyToSubmit: "Ready to Review",

  batchUpload: "Batch Upload Appraisal Form",
  batchExport: "Batch Export",
  export: "Export Individual Form",
  deletePdf : "Delete Supporting Documents",

  supportDocReminder:
    "Please upload personal improvement plan if the appraisal score is below 60",

  MicroSoftWordDescription:
    "Microsoft Word 2013 is the minimum requirement for successful displaying frontline form QR code",
  hasFilterDescription:
    "Please search with Appraisal Cycle and Site/Department first",
  scanningProgress: "Scanning progress",

  startTime: "Start Time",
  endTime: "End Time",
  uploadTime: "Upload On",
  uploadBy: "Upload By",

  scanAlert:
    "System would scan PDF file and auto fill in the scores for special Appraisal.",
  scan: "Upload Individual Form",
  processing: "Processing",
  completed: "Completed",
  failed: "Failed",

  uploadSuccess: "Upload Success",

  yes: "Yes",
  no: "No",

  unrecognizedPages: "Unrecognized Pages: {pages}",
  appendAttachment: "Only for uploading supporting documents",

  frontlineReturnAlert:
    "The following remarks/ comments will be shown in Appraisal Path Section of appraisal form and can be viewed by all parties.",
  frontlineReturnWarning:
    "Note: Your adjustments in this form will not be kept after returning the form.",

  uploadPdfHelper: "Only accepts PDF file format",

  cancelTask: "Cancel Task",

  cancelReason: "Please write down your reason(s) of cancelling this task",

  cancelled: "Cancelled",

  missingScore: "Please input score",

  hasPDF: "Has Uploaded PDF",

  confirmTo: "Confirm to {action}?",

  lastSubmitWarning: "Warning: Cannot make any amendment after this submission",

  fileLimit: "File cannot not larger than {size}Mbs",

  appraisalStatus: "Appraisal Status",

  appraiseeInfo: "Appraisee Information",

  printAsPDF: "Save as PDF",

  notFinishedForm: "Please complete Appraisal Form before submit",
  currentSelect: " - Currently Selected",

  default: "Default",
  custom: "Custom",
  password: "Password",
  defaultPassword:
    "Default Password = Staff Number + Surname (E.g. 123456Chan)",

  disable: "Disable",
  enable: "Enable",

  confirmDisable: "Confirm to Disable ?",
  confirmEnable: "Confirm to Enable ?",
  confirmDelete: "Confirm to Delete ?",
  confirmRecall: "Confirm to Recall Appraisal?",

  user: " User",

  firstName: "First Name",
  lastName: "Last Name",

  passwordFormate:
    "Password must contain 8 characters including at least one uppercase character, one lowercase character and one number.",

  onlyUpperCaseLetter: "Only accept uppercase letter",

  disableOtherUsers: "Disable Other Users",
  file: "File",

  //admin-panel
  users: "Users",
  userList: "User List",
  immediateSupervisor: "Immediate Supervisor",
  approvalRole: "Approval Role",
  defaultApprovers: "Default Approvers",

  generateRandomSubordinate: "Generate Random Subordinate",
  supervisorDivider: "Supervisor",

  appraiseeStaffNo: "Appraisee Staff Number",
  supervisorStaffNo: "Supervisor Staff Number",
  supervisorStaffName: "Supervisor Staff Name",
  supervisorLevel: "Approval Sequence",
  reason: "Reason",
  Excel: "Excel",
  replaceAllData: "Replace All Data",
  subordinateNumber: "Subordinate Number",
  isPadding: "Is Padding",

  disableUserReason: "Reason to disable",
  disableUserPlaceholder: "E.g. Long Sick Leave / Maternity Leave, etc.",
  disableUserRemarkInactive:
    "Inactive = Long Sick Leave, Maternity Leave, etc.",
  disableUserRemarkTerminated: "Terminated = Resigned / Terminated",
  pwPattern:
    "Password must contain 8 characters including at least one uppercase character, one lowercase character and one number.",

  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
}

export default en
