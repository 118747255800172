export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.CLIENT_ID,
  redirect_uri: process.env.REACT_APP_URL + "/authentication/login-callback",
  response_type: "code",
  scope: "openid profile KaiShing.WebUIAPI",
  silent_redirect_uri:
    process.env.REACT_APP_URL + "/authentication/silent-callback",
  post_logout_redirect_uri:
    process.env.REACT_APP_URL + "/authentication/logout-callback",
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: false,
  monitorSession: false,
}

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_URL,
  jwks_uri:
    process.env.REACT_APP_URL + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: process.env.REACT_APP_URL + "/connect/authorize",
  token_endpoint: process.env.REACT_APP_URL + "/connect/token",
  userinfo_endpoint: process.env.REACT_APP_URL + "/connect/userinfo",
  end_session_endpoint: process.env.REACT_APP_URL + "/connect/endsession",
  check_session_iframe: process.env.REACT_APP_URL + "/connect/checksession",
  revocation_endpoint: process.env.REACT_APP_URL + "/connect/revocation",
  introspection_endpoint: process.env.REACT_APP_URL + "/connect/introspect",
}
