import React from "react"
import { Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"

const ViewButton = ({ onClick, ...rest }) => (
  <Button
    icon={<SearchOutlined />}
    size="small"
    shape="round"
    onClick={onClick}
    {...rest}
  >
    View
  </Button>
)

export default ViewButton
