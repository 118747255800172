import React from "react"
import { Tag } from "antd"
import { useIntl } from "react-intl"

const InactiveTag = ({ value, remarks = null }) => {
  const { formatMessage } = useIntl()

  const activeLabel = formatMessage({ id: "active" })
  const inactiveLabel = formatMessage({ id: "inactive" })
  const terminatedLabel = formatMessage({ id: "terminated" })

  return (
    <Tag color={!remarks && value ? "green" : value ? "orange" : "red"}>
      {!remarks && value
        ? activeLabel
        : value
        ? `${inactiveLabel} - ${remarks}`
        : terminatedLabel}
    </Tag>
  )
}

export default InactiveTag
